import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Enquiry from '../modules/Enquiry';

const PageTemplate = (props) => {
  const post = props.data.post;
  const { location } = props;
  useEffect(() => {
    document.body.classList.remove('header-sticky');
  }, []);
  return (
    <>
      <Enquiry
        props={props}
        post={post}
        location={location}
        modules={post.flexibleContent}
      />
    </>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query FormPageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      title
      uri
      ...ModuleEnquiryFragmentFragment
    }
  }
`;
