import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
const RoyalMalewane = loadable(() => import('./ProperyForms/RoyalMalewane'));
const TheSilo = loadable(() => import('./ProperyForms/TheSilo'));
const BirkenheadHouse = loadable(() =>
  import('./ProperyForms/BirkenheadHouse')
);
const LaResidence = loadable(() => import('./ProperyForms/LaResidence'));
const RoyalMemberClub = loadable(() =>
  import('./ProperyForms/RoyalMemberClub')
);
const PrivateResidences = loadable(() => import('./Enquiry/PrivateResidences'));
const PropertySelection = loadable(() => import('./Enquiry/PropertySelection'));
const ItinerariesGuestEnquiry = loadable(() =>
  import('./Enquiry/ItinerariesGuestEnquiry')
);
const Spa = loadable(() => import('./ProperyForms/Spa'));

const Form = ({ module, location }) => {
  const formId = module.formShortcode;
  return (
    <>
      <Helmet>
        <title>Guest Enquiry | The Royal Portfolio</title>
      </Helmet>
      {formId == 10 && <RoyalMalewane formId={formId} location={location} />}
      {formId == 11 && <TheSilo formId={formId} location={location} />}
      {formId == 12 && <BirkenheadHouse formId={formId} location={location} />}
      {formId == 13 && <LaResidence formId={formId} location={location} />}
      {formId == 15 && (
        <PrivateResidences formId={formId} location={location} />
      )}
      {formId == 14 && (
        <PropertySelection formId={formId} location={location} />
      )}
      {formId == 24 && (
        <ItinerariesGuestEnquiry formId={formId} location={location} />
      )}
      {formId == 25 ||
        (formId == undefined && (
          <RoyalMemberClub formId={formId} location={location} />
        ))}
      {(formId == 17 || formId == 21 || formId == 22 || formId == 23) && (
        <Spa formId={formId} location={location} />
      )}
    </>
  );
};

export default Form;
export const FormFragment = graphql`
  fragment FormFragment on WpProperty_Flexiblecontent_FlexibleContent_Form {
    # content
    fieldGroupName
    formShortcode
  }
`;
export const FormPageFragment = graphql`
  fragment FormPageFragment on WpPage_Flexiblecontent_FlexibleContent_Form {
    # content
    fieldGroupName
    formShortcode
  }
`;
